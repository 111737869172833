import React, { Component } from 'react';
import Music from '../MainActivity/Music'
import wx from 'weixin-js-sdk';
import '../../assets/css/postition.css'
import { queries } from '@testing-library/react';

import 'antd-mobile/dist/antd-mobile.css';
import { Modal, Button, WingBlank, WhiteSpace, Toast } from 'antd-mobile';

import axios from 'axios';

const prompt = Modal.prompt;

class MapActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            ishide:true,
            dbimage:"",
            title:"",
            alltotal:""
        }
    }
    render() {
        return (
            <div style={{ overflow: "hidden" }}>

                <img style={{ width: "100%", height: window.outerHeight, zIndex: -1 }} src={require('../../assets/image/map_bg2.jpg')}></img>
                <Music style={{ zIndex: 1 }} music={"s102.mp3"}></Music>

                <div className="top">
                    <img style={{position: "absolute" , width: "80px",top:60,right:20}}  src={require('../../assets/image/bt_huodong2.png')}  onClick={this.huodong}/>
                </div>

                {/* <div className="footer">
                    <img style={{width:"200px",marginBottom:20}} src={require('../../assets/image/bt_login.png')} onClick={this.login}></img>
                </div> */}

                <div className="region-list postition-6" onClick={this.shihuaclick}>
                    <div className="area-box">
                        <img className="shouzhi" style={{display:this.state.index == 5? "block" : "none",  width:22,height:33,position: "absolute",marginLeft:68,marginTop:34,zIndex:88}} src={require('../../assets/image/zhitou_icon2.png')}></img>
                        
                        <img className="dot"  src={this.state.index >= 6 ? require('../../assets/image/yuhubandao_up.png') : require('../../assets/image/yuhubandao_down.png')}></img>
                        <span className="pulse delay-01" style={{ borderColor: this.state.index >= 6 ? "#f90" : "#a2a9b4" }}  ></span>
                        <span className="pulse delay-02" style={{ borderColor: this.state.index >= 6 ? "#f90" : "#a2a9b4" }}></span>

                    </div>
                </div>
                <div className="region-list waite postition-5 waite-node" onClick={this.wenhuagongclick}>
                    <div className="area-box">
                        <img className="shouzhi" style={{display:this.state.index == 4? "block" : "none",  width:22,height:33,position: "absolute",marginLeft:68,marginTop:34,zIndex:88}} src={require('../../assets/image/zhitou_icon2.png')}></img>
                        

                        <img className="dot"  src={this.state.index >= 5 ? require('../../assets/image/dongfangbaihuo_up.png') : require('../../assets/image/dongfangbaihuo_down.png')}></img>
                        <span className="pulse delay-06" style={{ borderColor: this.state.index >= 5 ? "#f90" : "#a2a9b4" }}></span>
                        <span className="pulse delay-05" style={{ borderColor: this.state.index >= 5 ? "#f90" : "#a2a9b4" }}></span>
                        <span className="pulse delay-04" style={{ borderColor: this.state.index >= 5 ? "#f90" : "#a2a9b4" }}></span></div>

                </div>

                <div className="region-list waite postition-4 waite-node" onClick={this.zhengwuzhongxinclick}>
                    <div className="area-box">
                        <img className="shouzhi" style={{display:this.state.index == 3? "block" : "none",  width:22,height:33,position: "absolute",marginLeft:68,marginTop:34,zIndex:88}} src={require('../../assets/image/zhitou_icon2.png')}></img>
                        
                        <img className="dot" src={this.state.index >= 4 ? require('../../assets/image/fenghuangshan_up.png') : require('../../assets/image/fenghuangshan_down.png')}></img>
                        <span className="pulse delay-01" style={{ borderColor: this.state.index >= 4 ? "#f90" : "#a2a9b4" }}></span>
                        <span className="pulse delay-02" style={{ borderColor: this.state.index >= 4 ? "#f90" : "#a2a9b4" }}></span></div>

                </div>
                <div className="region-list waite postition-3 waite-node" onClick={this.tiyuchangclick}>
                    <div className="area-box">
                        <img className="shouzhi" style={{display:this.state.index == 2? "block" : "none",  width:22,height:33,position: "absolute",marginLeft:68,marginTop:34,zIndex:88}} src={require('../../assets/image/zhitou_icon2.png')}></img>
                        
                        <img className="dot"  src={this.state.index >= 3 ? require('../../assets/image/huanleshijie_up.png') : require('../../assets/image/huanleshijie_down.png')}></img>
                        <span className="pulse delay-01" style={{ borderColor: this.state.index >= 3 ? "#f90" : "#a2a9b4" }}></span>
                        <span className="pulse delay-02" style={{ borderColor: this.state.index >= 3 ? "#f90" : "#a2a9b4" }}></span></div>

                </div>

                

                <div className="region-list waite postition-2 waite-node" onClick={this.biesuclick}>
                    <div className="area-box">
                        <img className="shouzhi" style={{display:this.state.index == 1? "block" : "none",  width:22,height:33,position: "absolute",marginLeft:68,marginTop:34,zIndex:88}} src={require('../../assets/image/zhitou_icon2.png')}></img>
                        
                        <img className="dot"  src={this.state.index >= 2 ? require('../../assets/image/dongbaowentizhongxin_up.png') : require('../../assets/image/dongbaowentizhongxin_down.png')}></img>
                        <span className="pulse delay-01" style={{ borderColor: this.state.index >= 2 ? "#f90" : "#a2a9b4" }}></span>
                        <span className="pulse delay-02" style={{ borderColor: this.state.index >= 2 ? "#f90" : "#a2a9b4" }}></span></div>

                </div>

                <div className="region-list waite postition-1 waite-node" onClick={this.zhongjianyipingclick}>
                    <div className="area-box">
                        <img className="shouzhi" style={{ display:this.state.index == 0? "block" : "none" ,  width:22,height:33,position: "absolute",marginLeft:68,marginTop:34,zIndex:88}} src={require('../../assets/image/zhitou_icon2.png')}></img>
                        
                        <img className="dot"  src={this.state.index >= 1 ? require('../../assets/image/changningshiyanxiaoxue_up.png') : require('../../assets/image/changningshiyanxiaoxue_down.png')}></img>
                        <span className="pulse delay-01" style={{ borderColor: this.state.index >= 1 ? "#f90" : "#a2a9b4" }}></span>
                        <span className="pulse delay-02" style={{ borderColor: this.state.index >= 1 ? "#f90" : "#a2a9b4" }}></span></div>

                </div>

                <div className="footer">
                    <img style={{position: "absolute",bottom:0,marginLeft:"50%",left:"-90px", width: "180px", marginBottom: 50  }} src={require('../../assets/image/bt_choujiang2.png')} onClick={this.choujiangclick}></img>
    
                    {/* <span style={{position: "absolute",bottom:0,left:40,marginBottom:20,background:"#FFF06B",width:20,display:"block",textAlign:"center",lineHeight:"20px",height:20,color:"#000",borderRadius:"100%"}}>{+this.state.alltotal}</span> */}
                </div>

                <div className="mask" style={{ display: this.state.ishide ? 'none' : 'block', width: window.outerWidth, height: window.outerHeight, backgroundColor: '#000', opacity: 0.8, position: 'absolute', left: 0, top: 0,zIndex:98 }}>
                </div>
                <div className="showBuilding" style={{ display: this.state.ishide ? 'none' : 'block', position: "absolute",zIndex:99, left: window.outerWidth / 2 - 150, top: window.outerHeight / 2 - 190, width: 300, height: 380, margin: '0 auto' }}>
                    
                    <img style={{ position: "absolute",zIndex:99, width: 35, height: 35, left: 0,right: 0, top: -50, fontSize: 23 }} onClick={this.close} src={require('../../assets/image/guanbi2.png')}></img>

                    {/* <span style={{ position: "absolute", width: 35, height: 35, textAlign: 'center', border: 'solid 3px #ccc', borderRadius: 35, right: 0, top: -50, fontSize: 23, lineHeight: '35px', color: '#fff' }} onClick={this.close}>X</span> */}
                    <div style={{ position: "absolute",zIndex:99, width: 300, margin: '0 auto' }}>
                        <img style={{ width: '100%' }} src={this.state.dbimage} />
                    </div>
                    <div style={{ position: "absolute",zIndex:99, width: '100%', display: 'block', padding: 10, fontSize: 16, top: 210, textAlign: 'center', boxSizing: 'border-box', color: '#fff' }}>
                        {this.state.title}
                    </div>
                </div>

                
            </div>
        );
    }


    huodong=()=>{
        window.location.href="http://cnthby1.utf8jm.top/xhlightmap/info.html"
    }

    componentWillMount() {
        //获取进度
        this.getMypos();
    }

    componentDidMount() {
        let token = localStorage.getItem('yhbdtoken');

        // Toast.info('登录失效，请重新登录！-126', 1);
        if (token) {
             //初始化分享
            this.getjssdk();
        } else {
            Toast.info('登录失效，请重新登录！-131', 1);
            // alert("登录失效，请重新登录！");
            this.props.history.push('/');
        }
    }


    getjssdk=()=>{

        //第一步：https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=APPID&secret=APPSECRET  获取 ACCESS_TOKEN
        //第二部：https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=ACCESS_TOKEN&type=jsapi
        //第三部：签名算法 .split('#')[0]

        axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getshareinfo',"a=1"+"&token="+localStorage.getItem('yhbdtoken')+"&signurl="+encodeURIComponent(window.location.href.split('#')[0])+"&activity=MapActivity")
          .then((response)=> {
            console.log(response.data);
            // if(this.state.sid == ""){
            //   this.setState({
            //       bduid:response.data.sid,
            //       alltotal:response.data.alltotal
            //   })
              // alert("--------分享id-------"+response.data.sid);
               
            // }

            let total = response.data.alltotal - response.data.cjtotal;
            this.setState({
                alltotal:total
            })
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: response.data.appId, // 必填，公众号的唯一标识
                timestamp: response.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: response.data.nonceStr, // 必填，生成签名的随机串
                signature: response.data.signature,// 必填，签名，见附录1
                jsApiList: [
                  'onMenuShareAppMessage',
                  'onMenuShareTimeline'
                ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              })
               
           
            wx.ready(() => {
                //分享给朋友
                wx.onMenuShareAppMessage({
                  title: response.data.title, // 分享标题
                  desc: response.data.desc, // 分享描述           
                  link: response.data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: response.data.img, // 分享图标
                  type: '', // 分享类型,music、video或link，不填默认为link
                  dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                  success: function () {
                    // 用户确认分享后执行的回调函数
                //    this.fenxiangcishu();
                  },
                  cancel: function () {
                    // 用户取消分享后执行的回调函数
                  }
                })
               
                //分享到朋友圈
                wx.onMenuShareTimeline({
                  title: response.data.title, // 分享标题
                  link: response.data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: response.data.img, // 分享图标
                  success: function () {
                    // 用户确认分享后执行的回调函数
                    // this.fenxiangcishu();
                  },
                  cancel: function () {
                    // 用户取消分享后执行的回调函数
                  }
                })
            })
            
           
          })
          .catch(function (error) {
            console.log(error);
          });

    }



    /**
     * 获取当前进度
     */
    getMypos = () => {
        console.log(localStorage.getItem('yhbdtoken'))
        axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getmypos', "token=" + localStorage.getItem('yhbdtoken'))
            .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                    this.setState({
                        index: response.data.pos
                    })
                    if (!response.data.isbm) {
                        this.Login();
                    }

                    if(response.data.pos>=6){
                        Toast.info("恭喜已全部点亮，可前往\"幸运大抽奖\"内参与抽奖", 1);
                    }

                } else {
                    // alert(response.data.info)
                    //获取进度失败重新登录.
                    if (!this.getQueryString("code")) {

                        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx20adb46fd402c1d8&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
                        window.location.href = url;
                        //console.log("第一次进入")
                        // alert(encodeURIComponent(window.location.href))
                    } else {
                        //console.log('第二次进入且路径中存在code')
                        // alert(this.GetUrlParame("code"));
                        //后台请求微信返回openiD成功跳转登录界面
                        // let params = { 'wxcode': localStorage.getItem('code') }
                        // getOpenId(params).then(res=>{
                        //     console.log(res)
                        //     localStorage.setItem('openId',res.data.openid);
                        // }).catch(err=>{
                        //     console.log(err)
                        // })
                        
                
                        axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getweixininfo',"code="+this.getQueryString("code"))
                            .then((response)=> {
                                console.log(response.data);
                                    if(response.data.success){
                                      console.log(response.data.data.token)
                                      localStorage.setItem('yhbdtoken', response.data.data.token);
                                    
                                      // alert("---------yzid--------"+response.data.data.uid);
                                      // alert("--------sid---------"+this.state.sid);
                                    }else{
                                        // alert(response.data.info)
                                        Toast.info(response.data.info, 1);
                                    }
                
                            
                            })
                            .catch(function (error) {
                                console.log(error);
                                // alert("-----------------"+error);
                                Toast.info(error, 1);
                            });
                
                            if(!localStorage){
                                // alert("不支持")
                                Toast.info("不支持localStorage", 1);
                            }
                            
                            
                
                    }
                
                    this.props.history.push('/');
                }


            })
            .catch(function (error) {
                console.log(error);
            });

    }


    Login = () => {
        prompt(
            '完善信息',
            '请绑定您的姓名和电话',
            (login, password) => {
                axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/postbmdata', "token=" + localStorage.getItem('yhbdtoken') + "&name=" + login + "&phone=" + password)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            Toast.success("绑定成功", 1);
                            // alert("绑定成功")
                        } else {
                            // alert(response.data.info)
                            Toast.fail(response.data.info, 1);
                        }


                    })
                    .catch(function (error) {
                        console.log(error);
                        Toast.fail(error, 1);
                    });
            },
            'login-password',
            null,
            ['请输入您的姓名', '请输入您的联系电话'],
        )
    }


    close=()=>{
        this.setState({
            ishide:!this.state.ishide
        })
    }

    /**
     * 石化点击事件
     */
    shihuaclick = () => {
        if(this.state.index < 5){
            // alert("请先点亮文化宫");
            Toast.info('请先点亮东方百货', 1);
            return;
        }
        if (this.state.index < 6) {
            window.location.href="/xhlightmap/#/DiBiaoActivity/6"
        } else {    

            this.setState({
                ishide:!this.state.ishide,  
                dbimage:require('../../assets/image/yuhubandao.png'),
                title:"御湖半岛是荆门唯一一处珍稀半岛资产地貌，城北唯一一处实景湖山合院墅。项目以高端别墅为主，是难得的生态、康养、宜居全年龄社区。背靠龙凤山山脉，三面环湖，公园在怀，山水贵脉，人文雅境，是上等的风水宝地。一期占地8.2万方，由别墅和高层组成，现已全面交付，少量别墅在售。二期占地3.2万方，容积率1.2，由新中式合院及洋房组成。合院面积以96到260为主。每一户都赠送地下室、超大露台、前后花园，最大赠送面积可达500多平，在荆门可谓首屈一指。"
            })

        }

    }
    /**
     * 文化宫点击事件
     */
    wenhuagongclick = () => {
        if(this.state.index < 4){
            // alert("请先点亮政务中心");
            Toast.info('请先点亮龙凤山公园', 1);
            return;
        }
        if (this.state.index < 5) {
            window.location.href="/xhlightmap/#/DiBiaoActivity/5"
        } else {
            this.setState({
                ishide:!this.state.ishide,
                dbimage:require('../../assets/image/dongfangbaihuo.png'),
                title:"家门口的购物中心。东方百货作为荆门国民商超企业，经过二十一年的发展，目前总经营面积达20万平米，年销售近30亿元，经营类别涵盖百货、家电、超市、家具、餐饮、物流、金融、电子商务等多种业态。正式入驻御湖半岛的东方百货超市，将为周边居民提供性价比高、品类丰富的蔬菜瓜果、日化生鲜，现已试营业，12月18日正式营业！"
            })
        }

    }

    /**
     * 体育场点击事件
     */
    tiyuchangclick = () => {
        if(this.state.index < 2){
            Toast.info('请先点亮东宝文体中心', 1);
            return;
        }
        if (this.state.index < 3) {
            window.location.href="/xhlightmap/#/DiBiaoActivity/3"
        } else {
            this.setState({
                ishide:!this.state.ishide,
                dbimage:require('../../assets/image/huanleshijie.png'),
                
                title:"家门口的游乐园，荆门欢乐世界游乐园位于长宁大道北端，占地面积200亩，分为陆地乐园和水上乐园，设有过山车、花果山漂流等20多个游乐设施，领略色彩缤纷的游乐园、绿荫冉冉的树林和宽敞的休憩场地，简直就是时尚与自然生态的完美结合的胜地。"
            })
        }

    }
    /**
     * 政务中心点击事件
     */
    zhengwuzhongxinclick = () => {
        if(this.state.index < 3){
            // alert("请先点亮生态运动公园主体育场");
            Toast.info('请先点亮欢乐世界', 1);
            return;
        }
        if (this.state.index < 4) {
            window.location.href="/xhlightmap/#/DiBiaoActivity/4"
        } else {
            this.setState({
                ishide:!this.state.ishide,
                dbimage:require('../../assets/image/fenghuangshan.png'),
                title:"荆门新晋网红打卡地、天然氧吧——龙凤山公园。公园以田家冲水库为载体，以原有地形地貌为依托，分为三大特色区域，分别是四季都有花可观的浪漫花溪区、穿梭树林的山林体验区和环绕全区域的滨水休闲区，形成“一路五湾、湖光十色”的整体结构框架。"
            })
        }

    }

    /**
     * 别墅点击事件
     */
    biesuclick = () => {
        if(this.state.index < 1){
            Toast.info('请先点亮长宁实验学校', 1);
            return;
        }
        if (this.state.index < 2) {

            window.location.href="/xhlightmap/#/DiBiaoActivity/2"
        } else {
            this.setState({
                ishide:!this.state.ishide,
                dbimage:require('../../assets/image/dongbaowentizhongxin.png'),
                
                title:"东宝区文体中心项目是\"十三五\"期间东宝区重大公共文化基础设施建设项目，建设内容为\"五馆一场\"，具体为图书馆、文化馆、档案馆、羽毛球馆、游泳馆、剧场，项目总占地面积133537平方米。 "
            })
        }

    }

    /**
     * 中建一品点击事件
     */
    zhongjianyipingclick = () => {
        if (this.state.index < 1) {

            // this.props.history.push('/DiBiaoActivity/6');
            window.location.href="/xhlightmap/#/DiBiaoActivity/1"
        } else {
            this.setState({
                ishide:!this.state.ishide,
                dbimage:require('../../assets/image/changnongshiyanxiaoxue.png'),
                title:"出家门进校门。长宁实验学校是一所九年一贯制公办学校，已于2021年9月开学。占地138亩，配套建有学术报告厅、体育馆、食堂、学生公寓、400米标准运动场，总投资1.16亿元。长宁实验学校以荆门市实验小学为依托，实行“一校两区”的集团化办学，打破空间距离，实现师资共享、硬件共享、课程共享、文化共享和管理共享。"
            })
        }

    }



    choujiangclick = () => {
        // alert("抽奖事件")
        window.location.href="http://cnthby1.utf8jm.top/xhlightmap/mapdzp.html"
    }
}

export default MapActivity;