import React, { Component } from 'react';
import 'antd-mobile/dist/antd-mobile.css';
import { Modal, Button, WingBlank, WhiteSpace, Toast } from 'antd-mobile';

import Music from '../MainActivity/Music'
import wx from 'weixin-js-sdk';
import axios from 'axios';


export function getUrlToken(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]); return null;
}


export function getquery(key) {
  var arr = window.location.href.replace(/\?/ig, '&').replace('#', '&').split('&');
  for (let i = 0; i < arr.length; i++) {
    var tmp = arr[i].split('=');
    if (Array.isArray(tmp) && tmp.length == 2 && tmp[0] == key) {
      return tmp[1];
    }
  }
  return '';
}

class DiBiaoActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      second:4,
      myishide:false,
      sid: "",
      yzid: "",
      zimage: [require('../../assets/image/huanleshijie.png'),require('../../assets/image/changnongshiyanxiaoxue.png'),require('../../assets/image/dongbaowentizhongxin.png'),require('../../assets/image/huanleshijie.png'),   require('../../assets/image/fenghuangshan.png'), require('../../assets/image/dongfangbaihuo.png'), require('../../assets/image/yuhubandao.png')],
      image: [require('../../assets/image/huanleshijie1.png'),require('../../assets/image/changnongshiyanxiaoxue1.png'), require('../../assets/image/dongbaowentizhongxin1.png'),require('../../assets/image/huanleshijie1.png'),   require('../../assets/image/fenghuangshan1.png'), require('../../assets/image/dongfangbaihuo1.png'), require('../../assets/image/yuhubandao1.png')],
      text: ["家门口的游乐园，荆门欢乐世界游乐园位于长宁大道北端，占地面积200亩，分为陆地乐园和水上乐园，设有过山车、花果山漂流等20多个游乐设施，设有过山车、花果山漂流等20多个游乐设施，领略色彩缤纷的游乐园、绿荫冉冉的树林和宽敞的休憩场地，简直就是时尚与自然生态的完美结合的胜地。",
          "出家门进校门。长宁实验学校是一所九年一贯制公办学校，已于2021年9月开学。占地138亩，配套建有学术报告厅、体育馆、食堂、学生公寓、400米标准运动场，总投资1.16亿元。长宁实验学校以荆门市实验小学为依托，实行“一校两区”的集团化办学，打破空间距离，实现师资共享、硬件共享、课程共享、文化共享和管理共享 。",
          "东宝区文体中心项目是\"十三五\"期间东宝区重大公共文化基础设施建设项目，建设内容为\"五馆一场\"，具体为图书馆、文化馆、档案馆、羽毛球馆、游泳馆、剧场，项目总占地面积133537平方米。", 
          "家门口的游乐园，荆门欢乐世界游乐园位于长宁大道北端，占地面积200亩，分为陆地乐园和水上乐园，设有过山车、花果山漂流等20多个游乐设施，领略色彩缤纷的游乐园、绿荫冉冉的树林和宽敞的休憩场地，简直就是时尚与自然生态的完美结合的胜地。",
         "荆门新晋网红打卡地、天然氧吧——龙凤山公园。公园以田家冲水库为载体，以原有地形地貌为依托，分为三大特色区域，分别是四季都有花可观的浪漫花溪区、穿梭树林的山林体验区和环绕全区域的滨水休闲区，形成“一路五湾、湖光十色”的整体结构框架。", 
         "家门口的购物中心。东方百货作为荆门国民商超企业，经过二十一年的发展，目前总经营面积达20万平米，年销售近30亿元，经营类别涵盖百货、家电、超市、家具、餐饮、物流、金融、电子商务等多种业态。正式入驻御湖半岛的东方百货超市，将为周边居民提供性价比高、品类丰富的蔬菜瓜果、日化生鲜，现已试营业，12月18日正式营业！",
          "御湖半岛是荆门唯一一处珍稀半岛资产地貌，城北唯一一处实景湖山合院墅。项目以高端别墅为主，是难得的生态、康养、宜居全年龄社区。背靠龙凤山山脉，三面环湖，公园在怀，山水贵脉，人文雅境，是上等的风水宝地。一期占地8.2万方，由别墅和高层组成，现已全面交付，少量别墅在售。二期占地3.2万方，容积率1.2，由新中式合院及洋房组成。合院面积以96到260为主。每一户都赠送地下室、超大露台、前后花园，最大赠送面积可达500多平，在荆门可谓首屈一指。"],
      dqjd: "0",
      ishide: true,
      bduid: "",
    }
  }
  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        <img style={{ width: "100%", height: window.outerHeight, zIndex: -1 }} src={require('../../assets/image/zl_bg2.jpg')}></img>
        <Music style={{ zIndex: 1 }} music={"s102.mp3"}></Music>
        <div className="top">
          <img style={{ position: "absolute", width: "80px", top: 60, right: 20 }} src={require('../../assets/image/bt_huodong2.png')} onClick={this.huodong} />
        </div>

        <img style={{ width: "320px", height: "", position: "absolute", left: window.outerWidth / 2 - 160, top: window.outerHeight / 2 - 200 }} src={this.state.dqjd > 2 ? this.state.zimage[this.props.match.params.id] : this.state.image[this.props.match.params.id]}></img>
        

        <span style={{ color: "#ffffff", padding: 20, fontSize: 14, fontWeight: "bold", lineHeight:1.4 , with: "100%", position: "absolute", top: window.outerHeight / 2 + 20}}>{this.state.text[this.props.match.params.id]}</span>




        <div class="mask" style={{ display: this.state.myishide ? 'none' : 'block', width: window.outerWidth, height: window.outerHeight, backgroundColor: '#000', opacity: 1, position: 'absolute',zIndex:999, left: 0, top: 0 }}>
              <p style={{marginTop:10,marginLeft:10,position: "absolute",zIndex:999,opacity:0.65,fontSize:"20px",lineHeight:"36px",color:"#ffffff",display:"block",width:"36px" ,height:"36px",backgroundColor:"#000000",borderRadius:36,textAlign:"center"}}>{this.state.second}s</p>
              <img style={{position: "absolute",width: window.outerWidth, height: window.outerHeight}} src={require('../../assets/image/login_bg2.jpg')}></img>
        </div>



        <div class="mask" style={{ display: this.state.ishide ? 'none' : 'block', width: window.outerWidth, height: window.outerHeight, backgroundColor: '#000', opacity: 0.8, position: 'absolute', left: 0, top: 0 }}>
        </div>
        <div class="showBuilding" style={{ display: this.state.ishide ? 'none' : 'block', position: "absolute" , top: 80, width: "100%", height: 430, margin: '0 auto' }}>
          <div style={{textAlign: 'right',marginTop:"-75px"}}>
              <img style={{width:window.outerWidth/2}}  src={require('../../assets/image/fenxiangicon.png')}></img>
          </div>
          <img style={{ position: "absolute", width: 35, height: 35, left: 0, top: -75, fontSize: 23 }} onClick={this.close} src={require('../../assets/image/guanbi2.png')}></img>
          <div style={{ position: "absolute", width: 300, margin: '0 auto' }}>
          </div>
          <div style={{ position: "absolute", width: '100%', display: 'block', padding: 30, fontSize: 16, top: 180, boxSizing: 'border-box', color: '#fff' }}>
            <p style={{ color: "#E5C51A", fontSize: 18, fontWeight: "bold" }}>游戏说明</p>
            <p>点亮图片即可参观景点</p>
            <p>1、报名成功后将链接分享给好友[点击右上角分享好友]，整个活动周期每个用户ID助力机会仅有3次。</p>
            <p>2、邀请18名好友打开链接为你助力，3名好友助力完成即可点亮一处地标，一处地标点亮后方可前往下一地标进行分享助力点亮；</p>
            <p>3、六处地标全部点亮即可获得一次抽奖机会；</p>
          </div>
        </div>
 
        <div className="footer">
          <p style={{ marginBottom: 10, color: "#ffffff" }}>当前已有{this.state.dqjd}位好友帮助点亮</p>
          <div style={{textAlign: 'center'}}>
            <img style={{  width: window.outerWidth*0.5,height:"auto"}} onClick={this.zhuliclick}  src={this.state.sid == "" ? require('../../assets/image/bt_yaoqing2.png') : require('../../assets/image/bt_zhuli2.png')}></img>
          </div>
          <div style={{textAlign: 'center',marginTop:5}}>
            <img style={{ width: window.outerWidth*0.5,height:"auto",marginBottom: 30}} src={this.state.sid == "" ? require('../../assets/image/bt_fanhui2.png') : require('../../assets/image/bt_canjia2.png')} onClick={this.fanhui}>{}</img>
          </div>

        </div>
      </div>

    );
  }

  countFun = () => {
   
    var remaining = 4000;
 
    this.timer = setInterval(() => {
         //防止出现负数
       if (remaining > 1000) {
         remaining -= 1000;
         
         let second = Math.floor(remaining / 1000 % 60);
 
         this.setState({
             second:second
         })
       } else {
         clearInterval(this.timer);
         //倒计时结束时触发父组件的方法
         //this.props.timeEnd();
        //  localStorage.setItem('avd', "true");
         this.setState({
            myishide: true
          })
       }
     }, 1000);
   }





  fanhui = () => {
    window.location.href = "/xhlightmap/#/MapActivity"
  }

  huodong = () => {
    window.location.href = "http://cnthby1.utf8jm.top/xhlightmap/info.html"
  }


  getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }
  // 截取code
  GetUrlParame(parameName) {
    /// 获取地址栏指定参数的值
    /// <param name="parameName">参数名</param>
    // 获取url中跟在问号后面的部分
    var parames = window.location.search
    // 检测参数是否存在
    if (parames.indexOf(parameName) > -1) {
      var parameValue = ''
      parameValue = parames.substring(parames.indexOf(parameName), parames.length)
      // 检测后面是否还有参数
      if (parameValue.indexOf('&') > -1) {
        // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
        parameValue = parameValue.substring(0, parameValue.indexOf('&'))
        // 去掉参数名, 得到最终纯值字符串
        parameValue = parameValue.replace(parameName + '=', '')
        return parameValue
      }
      return ''
    }
  }


  componentWillMount() {

    //截取微信链接重定向
    // if (/from=[^&$?]{1,}(&|$)/.test(window.location.search) || /isappinstalled=[^&$?]{1,}(&|$)/.test(window.location.search)||/code=[^&$?]{1,}(&|$)/.test(window.location.search)||/state=[^&$?]{1,}(&|$)/.test(window.location.search)) {
    //   var newSearch = window.location.search.replace(/from=[^&$?]{1,}(&|$)/, '').replace(/isappinstalled=[^&$?]{1,}(&|$)/, '').replace(/code=[^&$?]{1,}(&|$)/, '').replace(/state=[^&$?]{1,}(&|$)/, '').replace(/&$|\?$/, '');
    //   var newUrl = window.location.origin + window.location.pathname + newSearch + window.location.hash;
    //   window.location.href = newUrl;
    // }


    // 获取url后面的参数
    // const uid = getUrlToken ('uid',this.props.location.search);
    
    const uid = getquery("sid");
    console.log("来源用户id" + uid);
    if (uid != null) {
      this.setState({
        sid: uid
      })
    }

    console.log("当前地标id" + this.props.match.params.id);
    
    


   
      // let state = {
      //     title:'御湖半岛',
      //     url:'#'
      // }
      // window.history.pushState(state,state.title,state.url)
  



  }


  
  componentDidMount() {

    this.wxcode();
    this.getjssdk();


    this.setState({
      myishide: false
    })
    this.countFun();

    // if (localStorage.getItem("avd")) {
    //   this.setState({
    //     myishide: true
    //   })

    // }else{

    //   this.setState({
    //     myishide: false
    //   })
    //   this.countFun();
    // }

    
    


  }

   //卸载组件取消倒计时
   componentWillUnmount(){
     clearInterval(this.timer);
   }


  wxcode = () => {
    // localStorage.setItem('token', '0294938358648131589202206');
    // return;
    // alert(this.getQueryString("code"))
    if (localStorage.getItem("yhbdtoken")) {
      
      return
    }
    if (!this.getQueryString("code")) {

      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx20adb46fd402c1d8&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
      window.location.href = url;

    } else {

      axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getweixininfo', "code=" + this.getQueryString("code"))
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            console.log(response.data.data.token)
            localStorage.setItem('yhbdtoken', response.data.data.token);

            this.setState({
              yzid: response.data.data.uid
            })
            // alert("---------yzid--------"+response.data.data.uid);
            // alert("--------sid---------"+this.state.sid);
          } else {
            // alert(response.data.info)
            // Toast.fail(response.data.info, 1);
          }


        })
        .catch(function (error) {
          console.log(error);
          // alert("-----------------"+error);
          Toast.fail(error, 1);
        });

      if (!localStorage) {
        Toast.fail("不支持缓存", 1);
      }



    }

  }



  /**
   * 助力
   */
  zhuliclick = () => {
    // alert("当前地标id"+this.props.match.params.id+"------ 用户id"+this.state.sid)
    if (this.state.sid == "") {
      this.setState({
        ishide: !this.state.ishide
      })
    } else {
      axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/dohelp', "sid=" + this.state.sid + "&mapid=" + this.props.match.params.id + "&token=" + localStorage.getItem('yhbdtoken'))
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            // alert("助力成功")
            Toast.success('助力成功', 1);
            let a = this.state.dqjd+1;
            this.setState({
              dqjd:a
            })
          } else {
            Toast.fail(response.data.info, 1);
            //   if (!this.getQueryString("code") && response.data.info =="用户不存在") {

            //     let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx32f19e83e223dab4&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
            //     window.location.href = url;
            //     //console.log("第一次进入")
            //     // alert(encodeURIComponent(window.location.href))
            // } else {
            //     //console.log('第二次进入且路径中存在code')


            //     // alert(this.GetUrlParame("code"));
            //     //后台请求微信返回openiD成功跳转登录界面
            //     // let params = { 'wxcode': localStorage.getItem('code') }
            //     // getOpenId(params).then(res=>{
            //     //     console.log(res)
            //     //     localStorage.setItem('openId',res.data.openid);
            //     // }).catch(err=>{
            //     //     console.log(err)
            //     // })


            //     axios.post('http://assist.jmgsm.top/weixin/Guohualightmap/getweixininfo',"code="+this.getQueryString("code"))
            //         .then((response)=> {
            //             console.log(response.data);
            //                 if(response.data.success){
            //                   console.log(response.data.data.token)
            //                   localStorage.setItem('token', response.data.data.token);
            //                   this.setState({
            //                     yzid:response.data.data.uid
            //                   })
            //                   // alert("---------yzid--------"+response.data.data.uid);
            //                   // alert("--------sid---------"+this.state.sid);
            //                 }else{
            //                     alert(response.data.info)
            //                 }


            //         })
            //         .catch(function (error) {
            //             console.log(error);
            //             alert("-----------------"+error);
            //         });

            //         if(!localStorage){
            //             alert("不支持")
            //         }



            // }


          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }

  close = () => {
    this.setState({
      ishide: !this.state.ishide
    })
  }


  /**
  * 获取当前进度
  */
  getjindu = (id) => {

    axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getpro', "sid=" + id + "&mapid=" + this.props.match.params.id)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          this.setState({
            dqjd: response.data.pro
          })

        } else {
          // alert(response.data.info)
        }
      })
      .catch(function (error) {
        console.log(error);

      });

  }





  getjssdk = () => {

    //第一步：https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=APPID&secret=APPSECRET  获取 ACCESS_TOKEN
    //第二部：https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=ACCESS_TOKEN&type=jsapi
    //第三部：签名算法

    axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getshareinfo', "a=1" + "&token=" + localStorage.getItem("yhbdtoken")+"&signurl="+encodeURIComponent(window.location.href.split('#')[0])+"&activity=DiBiaoActivity/"+this.props.match.params.id)
      .then((response) => {
        console.log(response.data);
        if (typeof response.data.tologin != "undefined" && response.data.tologin == 1) {
          console.log("token发生变化重新登录");
          localStorage.removeItem("yhbdtoken");
          // localStorage.clear();
          // window.location.reload();
          // alert("当前用户登录状态失效")
          // window.location.href="/#/"
          this.wxcode();
          // return;
        }


        if (this.state.sid == "") {
          this.setState({
            bduid: response.data.sid
          })
          // alert("--------分享id-------"+response.data.sid);
          this.getjindu(response.data.sid);
        }else{
          this.setState({
            bduid: response.data.sid
          })
          this.getjindu(this.state.sid);
        }
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: response.data.appId, // 必填，公众号的唯一标识
          timestamp: response.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: response.data.nonceStr, // 必填，生成签名的随机串
          signature: response.data.signature,// 必填，签名，见附录1
          jsApiList: [
            'onMenuShareAppMessage',
            'onMenuShareTimeline'
          ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })

        console.log('http://cnthby1.utf8jm.top/xhlightmap/#/DiBiaoActivity/' + this.props.match.params.id + "?uid=" + response.data.sid);
        wx.ready(() => {
          //分享给朋友
          wx.onMenuShareAppMessage({
            title: response.data.title, // 分享标题
            desc: response.data.desc, // 分享描述           
            link: response.data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: response.data.img, // 分享图标
            type: '', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              // 用户确认分享后执行的回调函数
              this.fenxiangcishu();
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })

          //分享到朋友圈
          wx.onMenuShareTimeline({
            title: response.data.title, // 分享标题
            link: response.data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: response.data.img, // 分享图标
            success: function () {
              // 用户确认分享后执行的回调函数
              this.fenxiangcishu();
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
        })


      })
      .catch(function (error) {
        console.log(error);
      });

  }



  fenxiangcishu = () => {
    // axios.post('http://assist.jmgsm.top/weixin/Guohualightmap/share',"a=1"+"&token="+localStorage.getItem('token'))
    //     .then((response)=> {
    //       console.log(response.data);
    //         if(response.data.success){
    //             alert("分享成功")
    //         }                
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
  }



}

export default DiBiaoActivity;
