import React, { Component } from 'react';
import wx from 'weixin-js-sdk';
import axios from 'axios';
import { Modal, Button, WingBlank, WhiteSpace, Toast } from 'antd-mobile';
import Music from './Music';


const setTitle = title => () => document.title = '';

class MainActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bduid:"",
            second:4,
        }
    }

    /**
     * 处理#url
     * @param {} name 
     */
    getQueryString(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }
    // 截取code
    GetUrlParame(parameName) {
        /// 获取地址栏指定参数的值
        /// <param name="parameName">参数名</param>
        // 获取url中跟在问号后面的部分
        var parames = window.location.search
        // 检测参数是否存在
        if (parames.indexOf(parameName) > -1) {
            var parameValue = ''
            parameValue = parames.substring(parames.indexOf(parameName), parames.length)
            // 检测后面是否还有参数
            if (parameValue.indexOf('&') > -1) {
                // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                parameValue = parameValue.substring(0, parameValue.indexOf('&'))
                // 去掉参数名, 得到最终纯值字符串
                parameValue = parameValue.replace(parameName + '=', '')
                return parameValue
            }
            return ''
        }
    }

    componentWillMount() {
        // let state = {
        //     title:'御湖半岛',
        //     url:'#'
        // }
        // window.history.pushState(state,state.title,state.url)
    }

    componentDidMount() {
          
        // localStorage.setItem('token', '0294938358648131589202206');
        // return;

        this.wxcode();
         //初始化分享
        this.getjssdk();




        // if(document.referrer){
        //     Toast.fail('跳转过来的',1);
        //     window.location.href = window.location.href;
        // }
        

        

        
       
        // console.log(window.outerHeight)



        
        this.countFun();

    }


      //卸载组件取消倒计时
   componentWillUnmount(){
    clearInterval(this.timer);
  }


    countFun = () => {
   
        var remaining = 4000;
     
        this.timer = setInterval(() => {
             //防止出现负数
           if (remaining > 1000) {
             remaining -= 1000;
             
             let second = Math.floor(remaining / 1000 % 60);
     
             this.setState({
                 second:second
             })
           } else {
             clearInterval(this.timer);
             //倒计时结束时触发父组件的方法
             //this.props.timeEnd();
             this.props.history.push('/MapActivity');

           }
         }, 1000);
       }




    wxcode=()=>{
        if (!localStorage) {
            // alert("不支持localStorage")
            Toast.fail('不支持localStorage',1);
        }
        if(localStorage.getItem("yhbdtoken")){
            return
        }
        // alert(this.getQueryString("code"))
        if (!this.getQueryString("code")) {
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx20adb46fd402c1d8&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
            window.location.href = url;
            console.log("第一次进入")
            // alert(encodeURIComponent(window.location.href))
        } else {
            console.log('第二次进入且路径中存在code'+this.GetUrlParame("code"))
            // alert(this.GetUrlParame("code"));
            //后台请求微信返回openiD成功跳转登录界面
            // let params = { 'wxcode': localStorage.getItem('code') }
            // getOpenId(params).then(res=>{
            //     console.log(res)
            //     localStorage.setItem('openId',res.data.openid);
            // }).catch(err=>{
            //     console.log(err)
            // })
            // alert(this.getQueryString("code") + '------------code')
            axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getweixininfo', "code=" + this.getQueryString("code"))
                .then((response) => {
                    console.log(response.data);
                    if (response.data.success) {
                        console.log(response.data.data.token)
                        localStorage.setItem('yhbdtoken', response.data.data.token);
                        // alert("-----------------" + localStorage.getItem('token'));
                    } else {
                        // alert(response.data.info)
                        Toast.fail(response.data.info,1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    // alert(error + 'error')
                    // Toast.fail(error,1);
                });
        }
    }

    render() {
        return (
            <div style={{ overflow: "hidden" }}>
                <p style={{marginTop:10,right:10,position: "absolute",zIndex:999,opacity:0.65,fontSize:"20px",lineHeight:"36px",color:"#ffffff",display:"block",width:"36px" ,height:"36px",backgroundColor:"#000000",borderRadius:36,textAlign:"center"}}>{this.state.second}s</p>
                <img style={{ width: "100%", height: window.outerHeight, zIndex: -1 }} src={require('../../assets/image/login_bg2.jpg')}></img>
                <Music style={{ zIndex: 1 }} music={"s102.mp3"}></Music>

                {/* <div className="top">
                    <img style={{position: "absolute" , width: "80px",top:55,right:20}}  src={require('../../assets/image/bt_huodong.png')}  onClick={this.huodong}/>
                </div> */}


                {/* <div className="footer">
                    <img style={{ width: "200px", marginBottom: 85 }} src={require('../../assets/image/bt_login2.png')} onClick={this.login}></img>
                    <img className="shouzhi" style={{width:30,height:45,position: "absolute",bottom:85-20,right:0,marginRight:"25%"}} src={require('../../assets/image/zhitou_icon2.png')}/>
                </div> */}
            </div>
        );
    }



    login = () => {
        this.props.history.push('/MapActivity');
        // alert("1111111111111111")
        // alert(localStorage.getItem('code'));
    }


    huodong=()=>{
        window.location.href="http://light.jmgsm.top/info.html"
    }





    getjssdk=()=>{

        // alert(window.location.href.split('#')[0])
        //第一步：https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=APPID&secret=APPSECRET  获取 ACCESS_TOKEN
        //第二部：https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=ACCESS_TOKEN&type=jsapi
        //第三部：签名算法  .split('#')[0]   encodeURIComponent   window.location.href
         
        axios.post('http://cnth.utf8jm.top/weixin/Guohualightmap/getshareinfo',"a=1"+"&token="+localStorage.getItem('yhbdtoken')+"&signurl="+encodeURIComponent(window.location.href.split('#')[0])+"&activity=MainActivity")
          .then((response)=> {
            console.log(response.data);

            // if (typeof response.data.tologin != "undefined" && response.data.tologin == 1) {
            //     localStorage.removeItem("token");
            //     // localStorage.clear();
            //     // window.location.reload();
            //     // alert("当前用户登录状态失效")
            //     // window.location.href="/#/"
            //     this.wxcode();
            //     return;
            //   }

            // if(this.state.sid == ""){
            //   this.setState({
            //       bduid:response.data.sid
            //   })
              // alert("--------分享id-------"+response.data.sid);
            //   this.getjindu();
            // }
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: response.data.appId, // 必填，公众号的唯一标识
                timestamp: response.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: response.data.nonceStr, // 必填，生成签名的随机串
                signature: response.data.signature,// 必填，签名，见附录1
                jsApiList: [
                  'onMenuShareAppMessage',
                  'onMenuShareTimeline'
                ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              })
               
           
            wx.ready(() => {
                //分享给朋友
                wx.onMenuShareAppMessage({
                  title: response.data.title, // 分享标题
                  desc: response.data.desc, // 分享描述           
                  link: response.data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: response.data.img, // 分享图标
                  type: '', // 分享类型,music、video或link，不填默认为link
                  dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                  success: function () {
                    // 用户确认分享后执行的回调函数
                //    this.fenxiangcishu();
                  },
                  cancel: function () {
                    // 用户取消分享后执行的回调函数
                  }
                })
               
                //分享到朋友圈
                wx.onMenuShareTimeline({
                  title: response.data.title, // 分享标题
                  link: response.data.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: response.data.img, // 分享图标
                  success: function () {
                    // 用户确认分享后执行的回调函数
                    // this.fenxiangcishu();
                  },
                  cancel: function () {
                    // 用户取消分享后执行的回调函数
                  }
                })
            })
            
           
          })
          .catch(function (error) {
            console.log(error);
          });

    }



}

export default MainActivity;