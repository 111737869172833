import React, { Component } from 'react';

 class Music extends Component {
     constructor(props) {
         super(props);
         this.state = { 
            music:true,
            loop:true
          }

        this.music = this.music.bind(this); 
        this.playMusic = this.playMusic.bind(this); 
        this.musicid = React.createRef();
     }



     componentDidMount() {
        // this.timerID = setTimeout(
         //  () => this.tick(),
         //  3000
        // );
        
        
        // document.addEventListener('scroll', this.playMusic)
        document.addEventListener('touchmove',this.playMusic)

        document.addEventListener("WeixinJSBridgeReady",this.playMusic,false);
        this.playMusic();
        // setTimeout(()=>this.playMusic(),2000);

        
        
       }
       
        
       playMusic() {
         //alert(3)
         this.setState({
             music: true
         });
         this.musicid.current.play();
        // alert('wx:'+this.state.music)
       } 
       
        
       
       music(){
        // const audioid = ReactDOM.findDOMNode(this.refs.music); 
           this.setState(prevState => ({
                  music: !prevState.music 
                 })); 
                if(this.state.music===true)   this.musicid.current.pause();
                else this.musicid.current.play();           
       }
     




     render() { 
        var musicimg = '';
        if(this.state.music)   musicimg = require('../../assets/image/music2.png')
        else   musicimg = require('../../assets/image/music2.png')
        const audioUrl = require('../../assets/media/'+this.props.music)


         return ( 
             <div>
                 <audio  ref={this.musicid}  style={{display:`none`}} src={audioUrl} id="music" loop controls></audio>
                <div className={`  poa music`} onClick={this.music}>
                    <img className={`${this.state.music?'music_img':'music_img1'}`}  src={musicimg} alt=""  style={{ width:35,height:35}}/>
                </div>
             </div>
          );
     }
 }
  
 export default Music;

