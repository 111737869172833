import React from 'react';
import './App.css';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { HashRouter as Router, Route } from "react-router-dom";

import MainActivity from './activity/MainActivity/MainActivity'
import MapActivity from './activity/MapActivity/MapActivity'
import DiBiaoActivity from './activity/MapActivity/DiBiaoActivity'

/**
 * 游标
 * npm install react-router-dom --save
 * 网络请求
 * npm isntall axios --save
 * 
 * 视频
 * npm install --save video-react react react-dom redux
 */


const setTitle = (title) => {
  document.title = title
};

function App() {
  return (
    <Router>

        <Route exact path="/"  component={MainActivity}  onEnter={setTitle("御湖半岛")}/>
        <Route exact path="/MainActivity"  component={MainActivity}  onEnter={setTitle("御湖半岛")}/>
        <Route path="/MapActivity" component={MapActivity} onEnter={setTitle("御湖半岛")} />
        <Route path="/DiBiaoActivity/:id" component={DiBiaoActivity} onEnter={setTitle("御湖半岛")} />
        
    </Router>
  );
}

export default App;
